body{
  margin:0;
  padding:0;
  font-family: 'Varela Round', sans-serif;
  background-color:aliceblue;
  min-height: 100%;
  font-weight: 500;
}
nav .brand-logo{
  margin-left: 20px;
  font-size: 1.5em;
  color: white;
}
nav ul a{
  font-size: 1em;
}
nav ul{
  margin-right: 30px;
}
th{
  text-transform: uppercase;
  font-weight: 600;
}
.submittedforms{
  font-size: 1.3em;
  color: darkblue;
  padding: 15px;
}
.collapsible-header i{
  color: lightgray;
}
.collapsible.popout > li{
  margin: 0px;
  box-shadow: none;
}
.contactform .collapsible-body{
  padding: 10px;
}
.shownotify{
  display: flex;
  align-items: center;
  text-align: center;
  margin:20px auto;
}
@media only screen and (max-width:500px){
nav .brand-logo{
  margin:10px 0px 0px 0px;
  padding-bottom: 10px;
  font-size:1.1em;
  text-align:center;
  line-height:22px;
}
nav a{
  color: white;
}
#mobile-nav{
padding-top: 20px;
}
nav ul a{
  font-size: 1em;
}
.submittedforms{
  font-size: 1.2em;
}

}